/* Desktop View */
@media (min-width: 1280px) {
  .contactForm {
    height: 166rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .contactUsDesText {
    width: 46%;
  }

  .contactUsDesText > p {
    font-size: 1.2rem;
    text-align: center;
  }

  .contactUsText {
    font-size: 3rem;
    font-weight: 700;
  }

  .contactUsHeader {
    height: 16rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .contactUsHeader h1 {
    color: black;
  }

  .contactUsBox {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    padding: 1.4rem;
    width: 20rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .contactUsBoxes {
    height: 22rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
  .contactUsBox:hover {
    background-color: #ded7ff;
  }
  .SuccessText {
    font-size: 3rem;
    font-weight: 600;
  }

  .purple-text {
    font-size: 1.2rem;
    color: #4c24ae;
    margin: auto;
  }

  .contactFormContainer {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 36rem;
    justify-content: space-evenly;
  }

  .submissionSuccessContainer {
    display: flex;
    width: 30%;
    height: 36rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  input,
  textarea {
    padding: 1rem;
    font-size: 1rem;
    background-color: #f2f6ff;
  }
  textarea {
    border: 2px solid black;
  }
  .sendButton {
    padding: 1rem;
    background-color: black;
    color: beige;
    width: 9.6rem;
    border-radius: 4%;
    font-size: 1.1rem;
  }

  .formLabelText {
    font-size: 2rem;
    font-weight: 700;
  }

  .contactFormSection {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10rem;
  }

  .imageContainerr {
    width: 50%;
  }
  .contactUsImage {
    width: 40rem;
    height: 34rem;
  }

  .calgary_map {
    object-fit: cover;
    margin-top: 4rem;
    height: 44rem;
    width: 100%;
  }

  .purpleBoxThick {
    margin: auto;
    width: 52%;
    height: 20rem;
    background-color: #4c24ae;
    color: white;
  }

  .purpleThickTextWrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 3rem;
    margin-top: 5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .contactForm {
    height: 200rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .contactUsDesText {
    width: 80%;
  }

  .contactUsDesText > p {
    text-align: center;
    font-size: 1rem;
  }

  .contactUsText {
    font-size: 3rem;
    font-weight: 700;
  }

  .contactUsHeader {
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .contactUsHeader h1 {
    color: black;
  }

  .contactUsBox {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    padding: 1.4rem;
    width: 20rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .contactUsBoxes {
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
  .contactUsBox:hover {
    background-color: #f2f6ff;
  }
  .SuccessText {
    font-size: 3rem;
    font-weight: 600;
  }

  .purple-text {
    font-size: 1.2rem;
    color: #4c24ae;
    margin: auto;
  }

  .contactFormContainer {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 36rem;
    justify-content: space-evenly;
  }

  .submissionSuccessContainer {
    display: flex;
    width: 30%;
    height: 36rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  input,
  textarea {
    padding: 1rem;
    font-size: 1rem;
    background-color: #f2f6ff;
  }

  textarea {
    border: 2px solid black;
  }
  .sendButton {
    padding: 1rem;
    background-color: black;
    color: beige;
    width: 9.6rem;
    border-radius: 4%;
    font-size: 1.1rem;
  }

  .formLabelText {
    font-size: 2rem;
    font-weight: 700;
  }

  .contactFormSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .imageContainerr {
    width: 40%;
  }

  .contactUsImageWrapper {
    display: flex;
    justify-content: center;
  }
  .contactUsImage {
    width: 70%;
  }

  .calgary_map {
    object-fit: cover;
    margin-top: 4rem;
    width: 100%;
    height: 24rem;
  }

  .purpleBoxThick {
    margin: auto;
    width: 70%;
    height: 40rem;
    background-color: #4c24ae;
    color: white;
  }

  .purpleThickTextWrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
  }
}

@media (max-width: 768px) {
  .contactForm {
    height: 200rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .contactUsDesText {
    width: 80%;
  }

  .contactUsDesText > p {
    text-align: center;
    font-size: 1rem;
  }

  .contactUsText {
    font-size: 3rem;
    font-weight: 700;
  }

  .contactUsHeader {
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .contactUsHeader h1 {
    color: black;
  }
  
  .contactUsBox {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    padding: 1.4rem;
    width: 20rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .contactUsBoxes {
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
  .contactUsBox:hover {
    background-color: #f2f6ff;
  }
  .SuccessText {
    font-size: 3rem;
    font-weight: 600;
  }

  .purple-text {
    font-size: 1.2rem;
    color: #4c24ae;
    margin: auto;
  }

  .contactFormContainer {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 36rem;
    justify-content: space-evenly;
  }

  .submissionSuccessContainer {
    display: flex;
    width: 30%;
    height: 36rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  input,
  textarea {
    padding: 1rem;
    font-size: 1rem;
    background-color: #f2f6ff;
  }

  textarea {
    border: 2px solid black;
  }
  .sendButton {
    padding: 1rem;
    background-color: black;
    color: beige;
    width: 9.6rem;
    border-radius: 4%;
    font-size: 1.1rem;
  }

  .formLabelText {
    font-size: 2rem;
    font-weight: 700;
  }

  .contactFormSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .imageContainerr {
    width: 40%;
  }

  .contactUsImageWrapper {
    display: flex;
    justify-content: center;
  }
  .contactUsImage {
    width: 70%;
  }

  .calgary_map {
    object-fit: cover;
    margin-top: 4rem;
    width: 100%;
    height: 20rem;
  }

  .purpleBoxThick {
    margin: auto;
    width: 70%;
    height: 40rem;
    background-color: #4c24ae;
    color: white;
  }

  .purpleThickTextWrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
  }
}
