.hero4Wrapper {
  height: 40rem;
  display: flex;
  background-color: #4b21ad;
  align-items: center;
  justify-content: center;
}
.hero4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero4 > p {
  font-size: 3rem;
  color: white;
  font-weight: 700;
}

.bluefonts {
  font-size: 3rem;
  color: #54e2e8;
  font-weight: 700;
}

@media (max-width: 768px) {
  .hero {
    height: 68rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 4rem;
    background-color: #f5f5ff;
  }

  .heroLeftContainer {
    margin-top: -5rem;
    margin-bottom: -10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
  }
  .hero-image-container {
    height: 20rem;
    display: flex;
    justify-content: center;
  }

  .hero-image {
    width: 90%;
  }

  .findMoreButton {
    padding: 1rem 2rem;
    font-size: 1rem;
    background-color: black;
    color: white;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
  }

  .heroText > p {
    font-size: 2.8rem;
    font-weight: 700;
  }

  .heroText > h1 {
    font-size: 2.8rem;
    font-weight: 700;
    color: black;
  }

  .hero-description > p {
    font-size: 1.1rem;
  }
  .hero3 {
    height: 100rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .rightContainer {
    justify-content: center;
    width: 400px;
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 300px 300px 300px;
  }

  img {
    width: 800px;
    margin-top: -10px;
  }

  .Card {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    padding: 1.4rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .Card:hover {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }

  .cardTitle {
    font-weight: 700;
    padding-bottom: 0.8rem;
  }

  .secondHeroTextContainer {
    margin-top: 2rem;
  }

  .qualityAces {
    font-weight: 700;
    color: #8455ff;
  }

  .whychooseus-text {
    font-size: 2.2rem;
    font-weight: 700;
    padding-top: 0.7rem;
  }

  .secondHeroTextContainer > p {
    font-size: 1.1rem;
  }
  .herothird {
    height: 74rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
    align-items: center;
    background-color: #f5f5ff;
  }

  .rightheroContainer {
    height: 42rem;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    flex-direction: column;
  }

  .herothreeimage {
    width: 80%;
  }

  .leftheroContainer {
    width: 85%;
  }
  .third-hero-description {
    font-size: 1.3rem;
    width: 80%;
  }

  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 120rem;
  }
  .discover-text {
    color: #9426ff;
  }

  .header-text {
    font-size: 2rem;
    font-weight: 700;
    padding-top: 0.7rem;
  }

  .cardWrapper {
    display: flex;
    height: 44rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .card-heading {
    font-weight: 700;
    font-size: 1.1rem;
  }

  .purpleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 67%;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 40rem;
    background-color: #4c24ae;
    color: white;
  }
  .contact-us-button {
    color: black;
    background-color: white;
    padding: 0.8rem;
  }

  .footerImage {
    width: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .hero {
    height: 68rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 4rem;
    background-color: #f5f5ff;
  }

  .heroLeftContainer {
    margin-top: -5rem;
    margin-bottom: -10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
  }
  .hero-image-container {
    height: 20rem;
    display: flex;
    justify-content: center;
  }

  .hero-image {
    width: 60%;
  }

  .findMoreButton {
    padding: 1rem 2rem;
    font-size: 1rem;
    background-color: black;
    color: white;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
  }

  .heroText > p {
    font-size: 2.8rem;
    font-weight: 700;
  }

  .heroText > h1 {
    font-size: 2.8rem;
    font-weight: 700;
    color: black;
  }

  .hero-description > p {
    font-size: 1.1rem;
  }
  .hero3 {
    height: 66rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .qualityAces {
    font-weight: 700;
    color: #8455ff;
  }
  .rightContainer {
    width: 62%;
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: 300px 300px;
  }

  img {
    width: 800px;
    margin-top: -10px;
  }

  .Card {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    padding: 1.4rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .Card:hover {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }

  .cardTitle {
    font-weight: 700;
    padding-bottom: 0.8rem;
  }

  .secondHeroTextContainer {
    margin-top: 2rem;
  }

  .whychooseus-text {
    font-size: 2.2rem;
    font-weight: 700;
    padding-top: 0.7rem;
  }

  .secondHeroTextContainer > p {
    font-size: 1.1rem;
  }
  .herothird {
    height: 68rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    background-color: #f5f5ff;
  }

  .rightheroContainer {
    height: 42rem;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    flex-direction: column;
  }

  .herothreeimage {
    width: 55%;
    margin: auto;
  }

  .leftheroContainer {
    width: 85%;
    display: flex;
  }
  .third-hero-description {
    font-size: 1.3rem;
    width: 80%;
  }
  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 90rem;
  }
  .discover-text {
    color: #9426ff;
  }

  .header-text {
    font-size: 2rem;
    font-weight: 700;
    padding-top: 0.7rem;
  }

  .cardWrapper {
    display: flex;
    height: 44rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .card-heading {
    font-weight: 700;
    font-size: 1.1rem;
  }

  .purpleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 67%;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 40rem;
    background-color: #4c24ae;
    color: white;
  }
  .contact-us-button {
    color: black;
    background-color: white;
    padding: 0.8rem;
  }

  .footerImage {
    width: 110px;
  }
}

@media (min-width: 1280px) {
  .hero {
    height: 54rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8%;
    background-color: #f5f5ff;
  }

  .heroLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }

  .qualityAces {
    font-weight: 700;
    color: #8455ff;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
  }

  .heroText > p {
    font-size: 3.5rem;
    font-weight: 700;
  }

  .heroText > h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: black;
  }

  .hero-description > p {
    font-size: 1.3rem;
  }

  .hero-image-container {
    margin-right: -4rem;
    width: 48rem;
  }
  .hero-image {
    width: 100%;
  }
  .hero3 {
    height: 58rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16%;
    align-items: center;
  }

  .rightContainer {
    width: 30%;
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: 300px 300px;
  }
  img {
    width: 800px;
    margin-top: -10px;
  }

  .Card {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    padding: 1.4rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .Card:hover {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }

  .cardTitle {
    font-weight: 700;
    padding-bottom: 0.8rem;
  }

  .secondHeroTextContainer {
    padding-top: 2rem;
  }

  .whychooseus-text {
    font-size: 2.2rem;
    font-weight: 700;
    padding-top: 0.7rem;
  }

  .secondHeroTextContainer > p {
    font-size: 1.3rem;
  }

  .herothird {
    height: 54rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f5f5ff;
    gap: 6%;
  }

  .rightheroContainer {
    height: 36rem;
    width: 50%;
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-direction: column;
  }

  .herothreeimage {
    width: 60%;
  }

  .leftheroContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .third-hero-description {
    font-size: 1.3rem;
    width: 60%;
  }

  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 70rem;
  }
  .discover-text {
    color: #9426ff;
  }

  .header-text {
    font-size: 2rem;
    font-weight: 700;
    padding-top: 0.7rem;
  }

  .cardWrapper {
    display: grid;
    grid-template-columns: 32rem 32rem;
    grid-template-rows: 12rem 12rem;
  }

  .card-heading {
    font-weight: 700;
    font-size: 1.1rem;
  }

  .purpleBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60rem;
    height: 10rem;
    background-color: #4c24ae;
    color: white;
  }
  .purpleBoxText {
    /* font-size: 1.1rem; */
  }
  .contact-us-button {
    color: black;
    background-color: white;
    padding: 0.8rem;
    font-family: "DM Sans", sans-serif;
    width: 8.6rem;
  }

  .footerImage {
    width: 170px;
  }
}
