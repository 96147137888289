.Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  padding: 1.4rem;
  width: 17rem;
  height: 17rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Card:hover {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 17rem;
  height: 17rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ded7ff;
}

.cardTitle {
  font-weight: 700;
  padding-bottom: 0.8rem;
  text-align: center;
}

.cardDescription {
  text-align: center;
}