@media (min-width: 1280px) {
  .aboutUsBody {
    height: 130rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .aboutUsHeaderText {
    text-align: center;
  }

  .aboutUsTopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .aboutUsBodyHeader {
    font-size: 3.6rem;
    font-weight: 500;
    color: black;
  }

  .aboutUsTopContainerTextWrapper {
    width: 53%;
  }

  .squareCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 1rem;
    font-size: 1.05rem;
    padding: 7%;
    width: 100%;
    height: 20rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .squareCard:hover {
    scale: 105%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }
  .squareCardContainer {
    width: 90%;
    /* height: 22rem; */
    justify-content: center;
    display: grid;
    grid-template-columns: 13% 13% 13% 13% 13%;
    grid-template-rows: 13%;
    gap: 2%;
  }

  .squareCardImage {
    width: 4rem;
  }

  .displayOffDesktop {
    display: none !important;
  }

  .AboutUsLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4rem;
    width: 40%;
    height: 20rem;
  }

  .AboutUsBodySection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    width: 74rem;
    height: 25rem;
  }

  .ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
  }
  .aboutUspurpleBoxThick {
    width: 52%;
    height: 20rem;
    background-color: #4c24ae;
    color: white;
  }
  .aboutUsTextContainer {
    width: 30rem;
  }

  .aboutHeading {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .purpleBoxtexttWrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 3rem;
    margin-top: 2rem;
  }

  .lineheightsmall {
    line-height: 1.6;
  }
  .lineheight {
    line-height: 2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .displayOffMobile {
    display: none !important;
  }

  .aboutUsHeaderText {
    text-align: center;
  }

  .aboutUsBody {
    height: 200rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .aboutUsTopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .aboutUsBodyHeader {
    font-size: 3.6rem;
    font-weight: 500;
    color: black;
  }

  .aboutUsTopContainerTextWrapper {
    width: 53%;
  }

  .squareCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 1rem;
    font-size: 1rem;
    padding: 1rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .squareCard:hover {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    padding: 1rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }
  .squareCardContainer {
    width: 90%;
    height: 58rem;
    display: grid;
    grid-template-columns: 17.5rem 17.5rem;
    grid-template-rows: 17.5rem 17.5rem 17.5rem;
    justify-content: center;
    gap: 2rem;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  .squareCardImage {
    width: 4rem;
  }

  .AboutUsLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4rem;
    width: 90%;
    height: 42em;
  }

  .AboutUsBodySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    width: 95%;
    height: 46rem;
  }

  .ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .aboutUspurpleBoxThick {
    width: 52%;
    height: 20rem;
    background-color: #4c24ae;
    color: white;
  }
  .aboutUsTextContainer {
    width: 100%;
  }

  /* .aboutUsImage {
    width: 100%;
  } */

  .aboutHeading {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .purpleBoxtexttWrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 3rem;
    margin-top: 2rem;
  }

  .lineheightsmall {
    line-height: 1.6;
  }
  .lineheight {
    line-height: 2;
  }
}

@media (max-width: 768px) {
  .displayOffMobile {
    display: none !important;
  }

  .aboutUsHeaderText {
    text-align: center;
  }

  .aboutUsBody {
    height: 270rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .aboutUsTopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .aboutUsBodyHeader {
    font-size: 3.6rem;
    font-weight: 500;
    color: black;
  }

  .aboutUsTopContainerTextWrapper {
    width: 53%;
  }

  .squareCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 1rem;
    font-size: 1rem;
    padding: 1rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .squareCard:hover {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    padding: 1rem;
    width: 17rem;
    height: 17rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }
  .squareCardContainer {
    width: 90%;
    height: 90rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .squareCardImage {
    width: 4rem;
  }

  .AboutUsLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4rem;
    width: 90%;
    height: 42em;
  }

  .AboutUsBodySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    width: 95%;
    height: 46rem;
  }

  .ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .aboutUspurpleBoxThick {
    width: 52%;
    height: 20rem;
    background-color: #4c24ae;
    color: white;
  }
  .aboutUsTextContainer {
    width: 100%;
  }

  /* .aboutUsImage {
    width: 100%;
  } */

  .aboutHeading {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .purpleBoxtexttWrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 3rem;
    margin-top: 2rem;
  }

  .lineheightsmall {
    line-height: 1.6;
  }
  .lineheight {
    line-height: 2;
  }
}
