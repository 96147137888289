.powering-quality {
  font-family: 'DM Sans', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  background-color: white; /* Background color stays full width */
  color: black;
}

.quality-text {
  text-align: left;
  width: 50%; /* Text section takes 50% of the width */
  margin: 0 40px; /* Adds 40px margin on left and right of the text */
}

.quality-text h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  color: black;
  margin: 0 40px 20px 40px; /* Adds 40px margin on the sides of the heading */
}

.quality-text p {
  font-size: 1.2rem;
  margin: 0 40px 20px 40px; /* Adds 40px margin on the sides of the paragraph */
  line-height: 1.6;
  color: black;
}

.try-btn {
  padding: 15px 30px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 40px; /* Adds 40px margin around the button */
}

.try-btn:hover {
  background-color: #732d91;
}

.sub-text {
  margin: 40px; /* Adds 40px margin around subtext */
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.75);
}

.quality-image {
  width: 40%; /* Image section takes 40% of the width */
  text-align: right;
  margin: 0 40px; /* Adds 40px margin on the sides of the image */
}

.quality-image img {
  max-width: 100%;
  height: auto;
  transform: translateX(-40px);
}

.highlight {
  color: #8456FF;
}

@media (max-width: 1024px) {
  .powering-quality {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .quality-text {
    width: 100%; /* Full width for text on small screens */
    margin: 0 40px 30px 40px; /* Adjusts margin on smaller screens */
  }

  .quality-image {
    width: 100%; /* Full width for image on small screens */
    margin: 0 40px; /* Adjusts margin on smaller screens */
  }

  .quality-image img {
    max-width: 80%;
    margin: 0 auto;
  }
}
