.testing-potential {
  font-family: 'DM Sans', sans-serif;
  text-align: left;
  padding: 50px 20px; /* Retain padding for vertical spacing */
  background-color: white;
  color: black;
}

.testing-potential h2 {
  font-family: 'Inter', sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
  color: black;
  margin: 50px; /* Adds 40px margin around the heading */
  margin-bottom: 10px;
}

.testing-potential p {
  font-size: 1.2rem;
  color: black;
  margin: 50px; /* Adds 40px margin around the paragraph */
  margin-top: 20px;
}

.testing-features-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin: 50px; /* Adds 40px margin around the grid */
}

.testing-feature {
  background-color: white;
  color: #001f3f;
  width: 22%;
  padding: 10px;
  padding-top: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testing-feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.testing-feature h3 {
  margin: 20px 0 10px 0;
  font-size: 1.3rem;
}

.testing-feature p {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.icon {
  font-size: 2.5rem;
  color: #80e6b5;
}

@media (max-width: 1024px) {
  .testing-features-grid {
    flex-direction: column;
    align-items: center;
    margin: 40px; /* Maintains margin on smaller screens */
  }

  .testing-feature {
    width: 80%;
    margin-bottom: 20px;
  }
}
