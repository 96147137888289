.maximize-efficiency {
  font-family: 'DM Sans', sans-serif;
  text-align: center;
  padding: 60px 20px;
  background-color: white;  /* Dark blue background */
  color: white;
}

.maximize-efficiency h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  color: black;  /* Light greenish heading */
  margin-bottom: 40px;
}

.highlight {
  color: #8456FF; /* Custom color for highlighted word 'Efficiency' */
}

.efficiency-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Two columns */
  grid-gap: 30px;  /* Space between cards */
  max-width: 1200px;
  margin: 0 auto;  /* Center the grid */
}

.efficiency-card {
  background-color: white;
  color: #001f3f;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.efficiency-card:hover {
  transform: translateY(-10px);  /* Lift the card on hover */
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.efficiency-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.efficiency-card p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .efficiency-grid {
    padding-left: 40px;
    padding-right: 10px; /* Adjust padding for mobile view */
    margin: 0 auto;
    grid-template-columns: 1fr;  /* Stack the cards in a single column */
  }
  .maximize-efficiency h2 {
    font-size: 2rem; /* Reduce heading font size for smaller screens */
    padding-left: 30px
  }

}