.features {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for a 2x2 layout */
  gap: 20px; /* Space between cards */
  padding: 30px;
  background-color: #f4f4ff;
  max-width: 600px;
}

/* Individual feature card */
.feature {
  width: 272px;
  height: 272px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align content */
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
background-color: #bfbffb;
}

/* Icon styling */
.feature-check {
  font-size: 2rem;
  color: #53B44D;
  margin-bottom: 10px;
}

/* Title styling */
.feature h3 {
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

/* Description styling */
.feature p {
  font-family: 'DM Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .features {
    grid-template-columns: 1fr; /* Stacks cards in one column on smaller screens */
    margin: 0 auto; /* Center-align on smaller screens */
    transform: none; /* Reset transform for mobile view */
  }
}

/* Adjustments for .features container to center and space the feature cards */
.header {
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto; /* Centered margin but maintains the same margin on the right */
  background-color: #F4F4FF;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
}

.logo .logo-image {
  height: auto;
  width: 250px;
}

.nav-links-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-links {
  display: flex;
  gap: 40px;
}

.nav-links a, .sign-in-btn {
  text-decoration: none;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 10px 15px;
  color: black;
}

.nav-links a:hover, .sign-in-btn:hover {
  color: #8456FF;
}

.sign-in-btn {
  background-color: #9426ff;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 0;
  font-size: 1.1rem;
  cursor: pointer;
}

.menu-btn {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  z-index: 100;
}

.menu-btn div {
  height: 3px;
  width: 25px;
  background-color: black;
}

@media (max-width: 1024px) {
  .header {
    padding: 10px 10px 10px 10px; /* Consistent padding including the right side */
  }

  .logo .logo-image {
    width: 200px;
  }

  .menu-btn {
    display: flex; /* Show menu button */
  }

  .nav-links-container {
    display: none; /* Initially hide the nav links */
    flex-direction: column;
    width: 100%;
    position: fixed;
    background-color: #F4F4FF;
    top: 0;
    left: 0;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 98;
  }

  .nav-links-container.active {
    display: flex; /* Show nav links when active */
  }

  .nav-links {
    flex-direction: column; /* Stack links vertically */
  }

  .nav-links a, .sign-in-btn {
    font-size: 1.5rem; /* Adjust font size for better readability on mobile */
    display: block; /* Ensure full width for easy tapping */
    text-align: center; /* Center-align text */
  }
}

@media (max-width: 768px) {

  .main-section {
    padding: 30px 40px;
    padding-left: 35px;
    padding-right: 30px;
     /* Adjust padding to ensure content doesn't touch the edges */
    margin: 0 auto; /* Remove any default margin to prevent horizontal scroll */
  }
  .features {
    grid-template-columns: 1fr; /* Ensures a single column layout for mobile */
  }
  .features {
    display: flex;         /* Utilizes flexbox for alignment */
    flex-direction: column; /* Stacks the feature cards vertically */
    align-items: center;    /* Centers the cards horizontally */
    justify-content: center; /* Centers the cards vertically if there's extra space */
    padding-left: 58px;     /* Adds padding around the content for spacing */
    margin: 0 auto;        /* Centers the entire container horizontally */
    width: 100%;           /* Ensures the container takes up the full width of its parent */
    max-width: 100%;       /* Prevents the container from extending beyond the screen size */
    box-sizing: border-box; /* Includes padding in the width calculation */
  }

  .feature {
    width: 90%;            /* Adjusts the width of each card to fit within the container */
    margin: 10px 0;        /* Adds vertical spacing between cards */
  }

  .feature h3 {
    font-size: 1rem; /* Reduces the title font size to prevent overflow */
    margin-bottom: 5px; /* Reduces margin to save space */
  }

  .feature p {
    font-size: 0.85rem; /* Reduces the paragraph font size to fit text better */
    line-height: 1.4; /* Adjusts line height for readability */
  }

  .feature-check {
    font-size: 1.5rem; /* Reduces the icon size to fit better and save space */
    margin-bottom: 5px; /* Reduces space below the icon */
  }
  
  .some-absolute-element {
    position: absolute;
    right: 100%; /* Incorrectly places element off-screen */
    transform: translateX(-100%); /* Correctly brings it back on screen */
  }
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  .features {
    display: grid;
    grid-template-columns: 1fr; /* Single column layout */
    gap: 10px; /* Maintain existing gap */
    padding: 30px; /* Maintain existing padding */
    background-color: #f4f4ff; /* Existing background color */
    max-width: 600px; /* Control maximum width */
    margin: 0 auto; /* Center align the grid in the middle of the page */
    transform: none; /* Remove any transforms that might misalign the content */
  }

  .feature {
    width: 100%; /* Each box takes full width of the container */
    height: auto; /* Height adjusted to content */
    padding: 20px; /* Maintain existing padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Maintain shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px; /* Adds bottom margin for spacing between boxes */
  }
}

/* Adjust existing media query for iPads to override any two-column settings */
@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    padding: 10px; /* Consistent padding on all sides */
    margin-right: 0; /* Ensure no margin on the right */
    width: 100%; /* Ensure the header spans the full width */
    max-width: none; /* Override any max-width set at higher breakpoints */
  }

  .logo .logo-image {
    width: 200px; /* Adjust logo width as needed */
  }

  .menu-btn {
    display: flex; /* Show the menu button */
  }

  .nav-links-container {
    display: none; /* Hide the navigation links by default */
    flex-direction: column;
    width: 100%; /* Full width to use the entire screen space */
    position: fixed;
    background-color: #F4F4FF;
    top: 0;
    left: 0;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 98;
  }

  .nav-links-container.active {
    display: flex; /* Display navigation links when active */
  }

  .nav-links {
    flex-direction: column; /* Stack links vertically */
    width: 100%; /* Ensure links use the full width */
  }

  .nav-links a, .sign-in-btn {
    font-size: 1.5rem; /* Adjust font size for better readability */
    display: block; /* Ensure full width for easy tapping */
    text-align: center; /* Center-align text */
    padding: 10px 0; /* Adjust padding to remove horizontal padding */
  }
}


