.streamlined-testing {
  font-family: 'DM Sans', sans-serif;
  text-align: center;
  padding: 60px 20px;
  background-color: #4B21AD;  /* Light greenish background color */
  color: black;
}

.streamlined-testing h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 2rem;
  color: white;
  margin-bottom: 20px;
}

.streamlined-testing p {
  font-size: 1.2rem;
  color: white;
  max-width: 800px;
  margin: 0 auto 30px auto;  /* Center the text */
}

.learn-more-button {
  padding: 15px 30px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-more-button:hover {
  background-color: #333;  /* Darker shade on hover */
}

/* Responsive adjustments for iPad sizes (max-width: 1024px) */
@media (max-width: 1024px) {
  .streamlined-testing {
    padding: 40px 15px; /* Reduce padding for smaller screens */
  }

  .streamlined-testing h2 {
    font-size: 1.8rem; /* Slightly reduce heading font size */
  }

  .streamlined-testing p {
    font-size: 1.1rem; /* Slightly reduce paragraph font size */
    max-width: 600px; /* Adjust max width to fit smaller screens */
  }

  .learn-more-button {
    padding: 12px 25px; /* Adjust button padding */
    font-size: 0.95rem; /* Slightly smaller font for button */
  }
}

/* Responsive adjustments for phone sizes (max-width: 768px) */
@media (max-width: 768px) {
  .streamlined-testing {
    padding: 25px; /* Padding on all sides for phone screens */
    text-align: center;/* Align text to the left for a slight shift */
  }

  .streamlined-testing h2 {
    font-size: 1.2rem; /* Reduce heading font size for smaller screens */
    padding-left: 30px
  }

  .streamlined-testing p {
    font-size: 1rem; /* Reduce paragraph font size */
    max-width: 100%; /* Allow full width on very small screens */
    margin-left: 10px; /* Slight left margin to move text left */
    padding-left: 50px;
    padding-right: 30px;
  }

  .learn-more-button {
    padding: 10px 20px; /* Smaller padding for button */
    font-size: 0.9rem; /* Smaller font size for button */
  }
}
