/* Basic styling for the main section */
.main-section {
  font-family: 'DM Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: #f5f5ff;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.text-content {
  max-width: 600px;
  text-align: left;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.main-heading {
  font-family: 'Inter', sans-serif;
  font-size: 60px;
  font-weight: 800;
  color: #000000;
  /* text-align: center; */
}

.highlight {
  color: #8456FF;
}

/* .image-content {
  margin-left: 40px;
}

.image-content img {
  width: 100%;
  max-width: 700px;
  height: auto;
} */

.button-container {
  /* margin-top: 140px; */
  margin-top: 20px;
}

.description {
  font-family: 'DM Sans', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  color: #000000;
  margin-top: 30px;
}

.main-section button {
  background-color: black;
  color: white;
  font-size: 18px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  padding: 15px 40px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  margin-top: 30px;
}

.main-section button:hover {
  background-color: #732d91;
}

/* Responsive adjustments for tablets (e.g., iPads) */
@media (max-width: 1024px) {
  .content {
    flex-direction: column;
    align-items: center; /* Center-align all content */
  }

  .text-content, .image-content {
    max-width: 100%;
    margin-right: 0;
    text-align: center; /* Center text for better readability */
  }

  .main-heading {
    font-size: 50px; /* Adjust size for better fit on smaller devices */
  }

  .description {
    text-align: center; /* Center-align description text */
  }

  .main-section button {
    margin-top: 20px; /* Add margin for spacing above button */
  }
}

/* Responsive adjustments for mobile phones */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .main-heading {
    font-size: 36px; /* Further reduce size for mobiles */
  }

  .description {
    font-size: 1.1rem; /* Smaller text for mobiles */
  }
}

/* Basic styling adjustments for mobile sizes */
/* Responsive adjustments for mobile phones */
@media (max-width: 768px) {
  .main-section {
    margin: 0 auto; /* Remove any default margin to prevent horizontal scroll */
    padding-left: 20px; /* Adds padding on the left for phone screens */
    padding-right: 20px; /* Adds padding on the right for phone screens */
    margin-left: 10px; /* Adds left margin for phone screens */
  }

  .content {
    flex-direction: column; /* Stack content vertically for better readability */
    align-items: center; /* Center-align items for a cleaner layout */
    padding: 0; /* Remove padding that could affect width */
    margin: 0 auto; /* Ensure no margins are affecting layout */
    width: 100%; /* Full width to utilize all available space */
    overflow: hidden;
  }

  .text-content, .image-content {
    max-width: 100%; /* Ensure these elements do not exceed the viewport width */
    margin: 0; /* No margins to push content */
    padding-left: 20px; /* Adds padding on the left for text content on phones */
    padding-right: 20px; /* Adds padding on the right for text content on phones */
    text-align: center; /* Center text for better mobile readability */
    margin: 0 auto;
  }

  .nav-links-container, .nav-links {
    flex-direction: column; /* Stack links vertically for better access */
    align-items: center; /* Center align to match the content layout */
    width: 100%; /* Full width for better touch targets */
    margin: 0; /* No margin */
    padding-left: 20px; /* Adds padding on the left for nav links */
    padding-right: 20px; /* Adds padding on the right for nav links */
  }

  .nav-links a, .sign-in-btn {
    text-align: center; /* Center text for links */
    width: 100%; /* Full width for easier tap targets */
    padding: 10px; /* Adequate padding for tap targets */
    margin: 0; /* Ensure no margin which could cause layout shifts */
  }
  
  .nav-links {
    gap: 10px; /* Provide some vertical spacing between links */
  }

  .main-heading {
    font-size: 36px; /* Reduce size for mobile to fit better in smaller screens */
    margin: 0 auto;
  }

  .description {
    font-size: 1.1rem; /* Adjust font size for descriptions to enhance readability */
    margin: 0 auto;
  }
}

/* Further adjustment for very small devices under 480px */
@media (max-width: 480px) {
  .main-heading {
    font-size: 32px; /* Further reduce for smaller devices */
    margin: 0 auto;
    padding: 0 30px;
  }

  .description {
    font-size: 1rem; /* Make description text smaller to fit better on very small screens */
    margin: 0 auto;
    padding: 30px 40px;
    padding-left: 55px;
  }
}
