/* Desktop View */
@media (min-width: 1280px) {
  .aboutUsImage {
    border-radius: 5%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .squareCardWrapper {
    display: flex;
    width: 55%;
    align-items: center;
    justify-content: space-between;
  }
  .ServicesBodySection1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 7rem;
    height: 38rem;
    margin: 2rem 5rem;
    background-color: #f2f6ff;
  }

  .ServicesBodySection1 h1 {
    color: black;
  }

  .ServicesBodySection1Body {
    width: 30rem;
  }
  .ServicesBodySection2 {
    width: 90%;
    height: 30rem;
    margin: auto;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* gap: 2rem; */
  }

  .servicesCardImage {
    width: 60%;
  }

  .purplebackground {
    background-color: #f1eef8;
  }
  .bluebackground {
    background-color: #f2f6ff;
  }

  .ServicesBodySection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 30rem;
  }

  .ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
  }

  .ServicesBodySectionWrapper {
    display: flex;
    align-items: flex-start;
    width: 60%;
    justify-content: center;
    gap: 15%;
  }

  .ServicesLeftContainer {
    width: 30rem;
  }

  .purpleBoxContainer {
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ServicesImageContainer {
    width: 30rem;
    height: 20rem;
  }

  .centerAlignedText {
    font-size: 1.2rem;
  }
  .servicesSquareCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .aboutUsImage {
    border-radius: 5%;
    object-fit: cover;
    width: 390px;
    height: 240px;
  }
  .servicesSquareCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .squareCardWrapper {
    display: flex;
    width: 75%;
    align-items: center;
    gap: 3rem;
    justify-content: space-between;
  }
  .ServicesBodySection1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 5rem;
    height: 38rem;
    margin: 2rem 3rem;
    background-color: #f2f6ff;
  }

  .ServicesBodySection1 h1 {
    color: black;
  }

  .ServicesBodySection1Body {
    width: 90%;
  }
  .ServicesBodySection2 {
    height: 24em;
    margin: 1rem;
    padding-top: 4rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 4rem;
  }

  .servicesCardImage {
    width: 80%;
  }

  .purplebackground {
    background-color: #f1eef8;
  }
  .bluebackground {
    background-color: #f2f6ff;
  }

  .ServicesBodySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 48rem;
  }

  .ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100px;
  }

  .ServicesBodySectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: center;
    gap: 2rem;
  }

  .ServicesLeftContainer {
    width: 90%;
    margin: auto;
  }

  .ServicesImageContainer {
    /* padding-top: 1.2rem; */
  }

  .centerAlignedText {
    font-size: 1rem;
  }

  .purpleBoxContainer {
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  /* .ServicesBodyWrapper {
    height: 730rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

  .aboutUsImage {
    border-radius: 5%;
    object-fit: cover;
    width: 100%;
    height: 240px;
  }

  .squareCardWrapper {
    display: flex;
    flex-direction: column;
    height: 64rem;
    align-items: center;
    justify-content: center;
    gap: 2.2rem;
  }
  .servicesSquareCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ServicesBodySection1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 10%;
    height: 38rem;
    margin: 2rem 2rem;
    background-color: #f2f6ff;
  }

  .ServicesBodySection1 h1 {
    color: black;
  }

  .ServicesBodySection1Body {
    width: 90%;
  }
  .ServicesBodySection2 {
    height: 62em;
    margin: 1rem;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 4rem;
  }

  .ServicesBodySection3 {
    margin-top: 6rem;
  }

  .servicesCardImage {
    width: 8rem;
  }

  .purplebackground {
    background-color: #f1eef8;
  }
  .bluebackground {
    background-color: #f2f6ff;
  }

  .ServicesBodySection {
    /* margin: 4rem auto; */
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100px;
  }

  .purpleBoxContainer {
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ServicesBodySectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: center;
    gap: 2rem;
  }

  .ServicesLeftContainer {
    width: 90%;
  }

  .ServicesImageContainer {
    width: 90%;
  }

  .centerAlignedText {
    font-size: 1rem;
  }
}
