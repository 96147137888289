* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.copyrightDiv > p {
  background-color: #4c24ae;
  color: white;
  text-align: center;
  padding-bottom: 0.4rem;
}

/* Desktop View */
@media (min-width: 1280px) {
  .Doggera-footer {
    color: white;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 3%;
    background-color: #4c24ae;
    padding-right: 12rem;
    padding-left: 10rem;
  }

  .Doggera-footer li {
    list-style: none;
  }

  .Doggera-footer a {
    text-decoration: none;
    color: white;
  }

  .doggora-footer-list1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }

  .doggora-footer-list3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  .icon-image {
    width: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
  }
  .bigFonts {
    font-size: 1.2rem;
  }
  .footer-company-info {
    width: 15rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .Doggera-footer {
    color: white;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3%;
    background-color: #4c24ae;
    font-size: 0.8rem;
    /* padding-right: 2rem;
    padding-left: 2rem; */
  }

  .Doggera-footer li {
    list-style: none;
  }

  .Doggera-footer a {
    text-decoration: none;
    color: white;
  }

  .doggora-footer-list1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 16px;
  }

  .doggora-footer-list3 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
  }

  .icon-image {
    width: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
  }
  .bigFonts {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .Doggera-footer {
    color: white;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 3.2rem;
    background-color: #4c24ae;
  }

  .Doggera-footer li {
    list-style: none;
  }

  .Doggera-footer a {
    text-decoration: none;
    color: white;
  }

  .doggora-footer-list1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
  }

  .doggora-footer-list2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
  }

  .doggora-footer-list3 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
  }

  .icon-image {
    width: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
  }

  .footer-company-info {
    width: 40%;
  }
}
