@media (max-width: 768px) {
  .CareersBodyWrapper {
    height: 100%;
  }
  .careerHelpContainerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  .careerHelpContainer {
    width: 90%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .careerHelpContainerHeading {
    text-align: center;
    height: 6rem;
    font-size: 2rem;
    font-weight: 700;
  }

  .careerHelpHead {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .careerHelpImageWrapper {
    width: 90%;
  }

  .careerPageSection3 {
    display: flex;
    flex-direction: column;
    color: white;
    height: 44rem;
    background-color: #8457fe;
  }

  .careerPageSection3rightCotainer {
    width: 90%;
    display: flex;
    margin: auto;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .careerPageSection3Text > p {
    font-size: 1rem;
    /* line-height: 1.8; */
  }

  .careerPageSection2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    height: 40rem;
    text-align: center;
  }

  .careersPage1 {
    width: 70%;
  }

  .careerImage2 {
    width: 100%;
  }
  .careerPageSection1 {
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5ff;
    margin: 1rem;
  }

  .careerPageSection1Header {
    font-size: 3rem;
    font-weight: 500;
    color: black;
  }

  .careerPageSection1TextWrapper {
    width: 90%;
    font-size: 1rem;
    text-align: center;
  }

  .careerHelpContainerHeadingWrapper {
    width: 82%;
  }

  .careerPageSection4 {
    height: 75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .CareersPageImage4 {
    margin-top: -0.1rem;
    background-size: cover;
    width: 100%;
  }
  .careersPage1Wrapper {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .careerHelpContainerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  .careerHelpContainer {
    width: 70%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .careerHelpContainerHeading {
    text-align: center;
    height: 6rem;
    font-size: 2rem;
    font-weight: 700;
  }

  .careerHelpHead {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .careerHelpImageWrapper {
    display: flex;
    justify-content: center;
    width: 90%;
  }

  .careerPageSection3 {
    display: flex;
    flex-direction: column;
    color: white;
    height: 60rem;
    background-color: #8457fe;
  }

  .careerPageSection3rightCotainer {
    width: 90%;
    display: flex;
    margin: auto;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .careerPageSection3Text > p {
    font-size: 1rem;
    /* line-height: 1.8; */
  }

  .careerPageSection2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    height: 30rem;
  }

  .careersPage1 {
    width: 40%;
  }

  .careerImage2 {
    width: 50%;
  }
  .careerPageSection1 {
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5ff;
    margin: 1rem;
  }

  .careerPageSection1Header {
    font-size: 3rem;
    font-weight: 500;
    color: black;
  }

  .careerPageSection1TextWrapper {
    width: 90%;
    font-size: 1rem;
    text-align: center;
  }

  .careerHelpContainerHeadingWrapper {
    width: 82%;
  }

  .careerPageSection4 {
    height: 80rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .CareersPageImage4 {
    margin-top: -0.1rem;
    background-size: cover;
    width: 100%;
  }
  .careersPage1Wrapper {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .careerHelpContainerWrapper {
    display: flex;
    flex-direction: row;
    align-items: space-evenly;
    justify-content: center;
    gap: 3%;
  }

  .careerHelpContainer {
    width: 28%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .careerHelpContainerHeading {
    height: 10rem;
    font-size: 3rem;
    font-weight: 700;
  }

  .careerHelpHead {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .careerHelpImageWrapper {
    padding-top: 2rem;
    width: 20%;
  }

  .careerPageSection3 {
    display: flex;
    color: white;
    height: 35.4rem;
    background-color: #8457fe;
    /* align-items: center; */
    /* justify-content: center; */
    gap: 10%;
  }

  .careerPageSection3rightCotainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .careerPageSection3Text > p {
    font-size: 1.2rem;
    line-height: 1.8;
  }

  .careerPageSection2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50rem;
  }

  .careersPage1 {
    width: 70%;
  }

  .careerImage2 {
    width: 100%;
  }
  .careerPageSection1 {
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5ff;
    margin: 1rem;
  }

  .careerPageSection1Header {
    font-size: 3.6rem;
    font-weight: 500;
    color: black;
  }

  .careerPageSection1TextWrapper {
    width: 30%;
    font-size: 1.2rem;
    text-align: center;
  }

  .careerHelpContainerHeadingWrapper {
    width: 30rem;
  }

  .careerPageSection4 {
    height: 55rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .CareersPageImage4 {
    margin-top: 0.1rem;
    height: 35.2rem;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}
