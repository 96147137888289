@media (max-width: 768px) {
  .PrivacyPolicyContainer {
    width: 85%;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .PrivacyPolicyContainer {
    width: 85%;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

@media (min-width: 1280px) {
  .PrivacyPolicyContainer {
    width: 60%;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
