@media (max-width: 768px) {
  .purpleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 17rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 40rem;
    background-color: #4c24ae;
    color: white;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .purpleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 17%;
    width: 80%;
    height: 10rem;
    background-color: #4c24ae;
    color: white;
  }

  .purpleBoxLeftWrapper {
    width: 60%;
  }
}

@media (min-width: 1280px) {
  .purpleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 17%;
    width: 54rem;
    height: 10rem;
    background-color: #4c24ae;
    color: white;
  }

  .purpleBoxLeftWrapper {
    width: 55%;
  }
}
