/* Mobile View */
@media (min-width: 768px) {
  .tiny-logo {
    width: 25px;
  }
  .card-left-container {
    padding-top: 2rem;
    padding-left: 0.8rem;
    padding-right: 1rem;
  }
  .card-right-container {
    padding-top: 1.2rem;
    /* padding-left: 1rem; */
    padding-right: 1rem;
  }

  p,
  a {
    font-family: "DM Sans", sans-serif;
  }

  .horizontalCard {
    display: flex;
    flex-direction: row;
    padding: 2 2 2 0 rem;
    width: 30rem;
    height: 10rem;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .horizontalCard:hover {
    display: flex;
    flex-direction: row;
    padding: 2 2 2 0 rem;
    width: 30rem;
    height: 10rem;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .tiny-logo {
    width: 20px;
  }
  .card-left-container {
    padding-top: 1.6rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  .card-right-container {
    padding-top: 1.2rem;
    padding-right: 1rem;
  }
  .horizontalCard {
    display: flex;
    flex-direction: row;
    padding: 2 2 2 0 rem;
    width: 22rem;
    height: 10rem;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .horizontalCard:hover {
    display: flex;
    flex-direction: row;
    padding: 2 2 2 0 rem;
    width: 22rem;
    height: 10rem;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ded7ff;
  }
  p,
  a {
    font-family: "DM Sans", sans-serif;
  }
}
