.testing-workflow {
  font-family: 'DM Sans', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  background-color: #F4F4FF; /* White background remains full width */
  color: black;
}

.workflow-text {
  width: 45%; /* Text takes up 45% of the width */
  text-align: left;
  padding: 0 40px; /* Adds 40px padding on the left and right for internal spacing */
}

.workflow-text h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  color: black;
  padding: 0 40px; /* Adds 40px padding on the sides */
  margin-bottom: 20px;
}

.workflow-text p {
  font-size: 1.2rem;
  color: black;
  padding: 0 40px; /* Adds 40px padding on the sides */
  margin-bottom: 30px;
  line-height: 1.6;
}

.learn-more-btn {
  padding: 15px 30px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Adds space above the button */
  transform: translateX(40px);
}

.learn-more-btn:hover {
  background-color: #c2d6c9;
}

.sub-text {
  margin-top: 15px;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 40px; /* Adds 40px padding on the sides for spacing */
}

.workflow-image {
  width: 50%; /* Image takes up 50% of the width */
  text-align: right;
  padding: 0 40px; /* Adds 40px padding on the sides for spacing */
}

.workflow-image img {
  width: 70%;
  height: auto;
  transform: translate(-90px, 20px);
}

@media (max-width: 1024px) {
  .testing-workflow {
    flex-direction: column;
    text-align: center;
    padding: 0 40px; /* Adds padding on the sides for responsive layout */
  }

  .workflow-text {
    width: 100%;
    margin-bottom: 40px;
  }

  .workflow-image {
    width: 100%;
  }
}

.highlight {
  color: #8456FF; /* Apply the purple color to 'Workflow' */
}
