@media only screen and (max-width: 767px) {
  .navBar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    height: 8rem;
    background-color: #f5f5ff;
    /* background-color: #f97400; */
  }

  .mobileMenu > li {
    text-align: center;
    font-size: 1.6rem;
    color: #4d009a;
    border-bottom: 1px solid #4d009a;
  }

  .mobileLink {
    color: #4d009a;
  }

  .desktop-nav-links {
    display: none;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
    text-shadow: 0 0 5px rgb(0, 0, 0, 0);
    color: black;
  }

  a:hover {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
    transition: text-shadow 0.2s;
  }

  .logoImage {
    padding-top: 1rem;
    width: 200px;
  }

  h1 {
    color: white;
  }

  img {
    margin-top: 10px;
  }
  .hamburgerIcon {
    margin-top: 1.5rem;
    margin-left: -4rem;
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .navBar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    height: 8rem;
    padding-top: 1rem;
    background-color: #f5f5ff;
  }

  .mobileMenu > li {
    text-align: center;
    font-size: 1.6rem;
    /* border-top: 1px solid black; */
    border-bottom: 1px solid #4d009a;
  }
  .desktop-nav-links {
    display: none;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
    text-shadow: 0 0 5px rgb(0, 0, 0, 0);
    color: black;
  }

  a:hover {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
    transition: text-shadow 0.2s;
  }

  .contact-button {
    display: none;
  }
  .contact-button > a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
    text-shadow: 0 0 5px rgb(255, 255, 255, 0);
  }

  .logoImage {
    padding-top: 1rem;
    width: 200px;
  }

  h1 {
    color: white;
  }

  img {
    margin-top: 10px;
  }
  .hamburgerIcon {
    margin-top: 1.5rem;
    margin-left: -4rem;
    display: block;
  }

  .mobileMenu li a:visited {
    color: #4d009a; /* Change this to the color you want for visited links */
  }
}

@media only screen and (min-width: 1280px) {
  .navBar {
    display: flex;
    justify-content: center;
    gap: 22%;
    align-items: center;
    height: 8rem;
    padding-top: 1rem;
    background-color: #f5f5ff;
    /* position: fixed; */
    width: 100%;
  }

  .desktop-nav-links {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 43rem;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
    text-shadow: 0 0 5px rgb(255, 255, 255, 0);
    color: black;
  }

  a:hover {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
    transition: text-shadow 0.2s;
  }

  .contact-button {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 2%;
    background-color: #9426ff;
    color: white;
  }

  .contact-button:hover {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 2%;
    background-color: #4d009a;
  }
  .contact-button > a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
    text-shadow: 0 0 5px rgb(255, 255, 255, 0);
  }

  .logoImage {
    padding-top: 1rem;
    width: 230px;
  }

  h1 {
    color: white;
  }

  img {
    margin-top: 10px;
  }
  .hamburgerIcon {
    display: block;
  }
}
